<template>
  <div>
    <el-form
      ref="form"
      :model="form"
      :rules="rules"
      :label-position="this.$store.getters.isPCM ? 'top' : 'right'"
      label-width="80px"
      id="showMessage"
    >
      <el-divider content-position="right">留言板</el-divider>

      <el-form-item label="昵称" prop="name">
        <el-input v-model="form.name"></el-input>
      </el-form-item>
      <el-form-item label="邮箱" prop="email">
        <el-input v-model="form.email"></el-input>
      </el-form-item>
      <el-form-item label="内容" prop="content">
        <el-input
          type="textarea"
          v-model="form.content"
          name="writers"
          id="writers"
          rows="5"
        ></el-input>
        <label class="zb">谁是人间惊鸿客...</label>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submit">立即创建</el-button>
        <el-button @click="resetForm">重置</el-button>
      </el-form-item>
    </el-form>

    <div class="showcomments">
      <blockquote style="margin: 30px 0; font-size: 18px">
        总计{{ messageData.comments.total }}条
        <a
          href="https://www.17sucai.com/preview/776298/2019-10-23/dm/index.html"
          target="_blank"
          style="margin-left: 10px; text-decoration: none; color: black"
        >
          <el-tag type="success">查看弹幕星空</el-tag>
        </a>
      </blockquote>

      <ul id="soLi">
        <li
          v-for="(item, index) in messageData.comments.list"
          :key="index"
          style="
            width: 100%;
            margin-bottom: 30px;
            position: relative;
            word-wrap: break-word;
            word-break: break-all;
            color: dimgrey;
          "
        >
          <div class="writersdata">
            <a
              href="javascript:;"
              class="writer"
              style="display: inline-block; width: 64px"
            >
              <img
                :src="item.headerUrl"
                width="64px"
                height="64px"
                style="border-radius: 35px"
              />
            </a>
            <div
              style="
                display: inline-block;
                position: absolute;
                height: 64px;
                margin-left: 17px;
              "
            >
              <p style="font-size: 19px; line-height: 1.7; color: #72777b">
                {{ item.name }}
                <img
                  :src="
                    'https://oss.yaimer.com/static/yaimer_static/bilibili_level/level_' +
                    item.grade +
                    '.svg'
                  "
                  width="28px"
                  height="100%"
                />
              </p>
              <p style="font-size: 13px; color: rgb(47 28 28 / 35%)">
                <span v-text="common.dateFormatter(item.insertTime)"></span
                >&nbsp;&nbsp;|&nbsp;&nbsp;
                <span>
                  <img
                    :src="
                      'https://oss.yaimer.com/static/yaimer_static/browser/' +
                      item.browser +
                      '.svg'
                    "
                    width="13px"
                    height="13px"
                  />
                  {{ item.browser }}&nbsp;&nbsp;|&nbsp;&nbsp;
                </span>
                <span>
                  <img
                    :src="
                      'https://oss.yaimer.com//static/yaimer_static/system/' +
                      item.system +
                      '.svg'
                    "
                    width="13px"
                    height="13px"
                  />
                  {{ item.system }}&nbsp;&nbsp;|&nbsp;&nbsp;
                </span>
                <span v-text="item.address"></span>
              </p>
            </div>
            <div
              class="commentcontent"
              style="margin-top: 20px; font-size: 15px; text-indent: 2em"
            >
              {{ item.content }}
            </div>

            <div
              style="margin-left: 8%; width: 92%"
              class="replycon"
              v-if="item.isReply == 1"
            >
              <hr class="layui-bg-grey" />
              <div class="writersdata">
                <a
                  href="javascript:;"
                  class="writer"
                  style="display: inline-block; width: 50px"
                >
                  <img
                    :src="messageData.about.headerUrl"
                    width="64px"
                    height="64px"
                    style="border-radius: 35px; object-fit: cover"
                  />
                </a>
                <div
                  style="
                    display: inline-block;
                    position: absolute;
                    height: 64px;
                    margin-left: 17px;
                  "
                >
                  <p>
                    <span
                      style="
                        border: 1px solid #ff81aa;
                        min-width: 40px;
                        display: inline-block;
                        height: 22px;
                        line-height: 20px;
                        text-align: center;
                        font-size: 17px;
                        color: #ff81aa;
                        border-radius: 3px;
                        margin-right: 5px;
                        vertical-align: middle;
                        font-weight: normal;
                      "
                    >
                      博主
                    </span>
                    <span style="font-size: 17px; color: #72777b">
                      {{ messageData.about.name }}
                    </span>
                    <img
                      src="https://oss.yaimer.com/static/yaimer_static/bilibili_level/level_6.svg"
                      width="28px"
                      height="100%"
                    />
                  </p>
                  <p style="font-size: 13px; color: rgb(47 28 28 / 35%)">
                    &nbsp;&nbsp;
                    <span>
                      <img
                        src="https://oss.yaimer.com//static/yaimer_static/browser/chrome.svg"
                        width="13px"
                        height="13px"
                      />
                      {{ messageData.about.browser }}&nbsp;&nbsp;|&nbsp;&nbsp;
                    </span>
                    <span>
                      <img
                        src="https://oss.yaimer.com/static/yaimer_static/system/win10.svg"
                        width="13px"
                        height="13px"
                      />
                      {{ messageData.about.system }}&nbsp;&nbsp;|&nbsp;&nbsp;
                    </span>
                    <span>
                      {{ messageData.about.address }}
                    </span>
                  </p>
                </div>
              </div>
              <div
                class="commentcontent"
                style="
                  margin-top: 18px;
                  font-size: 15px;
                  margin-bottom: 15px;
                  text-indent: 2em;
                "
              >
                {{ item.replyContent }}
              </div>
            </div>
          </div>
          <label
            style="
              position: absolute;
              right: 0;
              bottom: 0px;
              font-size: 9px;
              color: grey;
              border: 1px solid aliceblue;
              border-radius: 30%;
            "
          >
            {{
              messageData.comments.total -
              ((messageData.comments.pageNum - 1) *
                messageData.comments.pageSize +
                index)
            }}楼
          </label>
          <el-divider class="layui-bg-blue"></el-divider>
        </li>
      </ul>
      <div style="text-align: center">
        <el-pagination
          background
          layout="prev, pager, next"
          :total="messageData.comments.total"
          :small="this.$store.getters.isPCM ? true : false"
          :page-size="10"
          :current-page="1"
          @current-change="getData"
          style="display: inline-block"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";

export default {
  data() {
    var checkEmail = (rule, value, callback) => {
      const regEmail =
        /^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
      if (regEmail.test(value)) {
        return callback();
      }
      callback(new Error("请输入合法的邮箱"));
    };
    return {
      messageData: {
        about: {},
        comments: [],
      },
      form: {
        name: "",
        email: "",
        content: "",
      },
      rules: {
        name: [
          {
            required: true,
            message: "昵称和邮箱是留言的重要依据，岂能为空？",
            trigger: "blur",
          },
          {
            min: 1,
            max: 10,
            message: "长度在 1 到 10 个字符",
            trigger: "blur",
          },
        ],
        email: [
          {
            required: true,
            message: "昵称和邮箱是留言的重要依据，岂能为空？",
            trigger: "blur",
          },
          { validator: checkEmail, trigger: "blur" },
        ],
        content: [
          {
            required: true,
            message: "留言内容输入不可为空！-------",
            trigger: "blur",
          },
          {
            min: 3,
            max: 150,
            message: "留言内容长度在 3 到 150 个字符",
            trigger: "blur",
          },
        ],
      },
    };
  },
  created() {
    this.getData(1);
  },
  mounted() {
    $("#writers").focus(function () {
      $(".zb").animate(
        {
          top: -8,
          "font-size": 12,
        },
        300
      );
      $(".zb").css(
        {
          background: "#ffa600",
          color: "white",
        },
        300
      );
    });
    $("#writers").blur(function () {
      if ($("#writers").val().length <= 0) {
        $(".zb").animate(
          {
            top: 23,
            "font-size": 14,
          },
          300
        );
        $(".zb").css({
          background: "white",
          color: "rgb(133, 133, 133)",
        });
      }
    });
  },
  methods: {
    getData(page) {
      let that = this;
      this.$axios
        .get("/blog/getMessage", {
          params: {
            curr: page,
            size: 10,
          },
        })
        .then(function (resp) {
          that.messageData = resp.data;
          window.scrollTo(0, 0);
        });
    },
    submit() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.sendMail();
        } else {
          this.$notify.error({
            title: "错误",
            message: "请确认必填选项格式是否正确!",
          });
          return false;
        }
      });
    },
    sendMail() {
      const that = this;
      this.$axios
        .get("/blogApi/sendMail", {
          params: {
            mail: that.form.email,
          },
        })
        .then(function (resp) {
          if (resp.data.code === 200) {
            that
              .$prompt("请输入邮箱收到的验证码", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                inputValidator: (value) => {
                  if (!value) {
                    return "验证码不可为空！";
                  }
                },
              })
              .then(
                ({ value }) => {
                  that.sendCode(value);
                },
                () => {} //取消输入
              );
          } else {
            that.$message({
              showClose: true,
              message: resp.data.msg,
              type: "error",
            });
          }
        });
    },
    sendCode(value) {
      const that = this;
      this.$axios
        .get("/blogApi/sendCode", {
          params: {
            mail: that.form.email,
            code: value,
            content: that.form.content,
            name: that.form.name,
          },
        })
        .then(function (resp) {
          if (resp.data.code === 200) {
            that.$message({
              showClose: true,
              message: "留言成功！",
              type: "success",
              onClose: function sub() {
                that.getData(1);
                that.resetForm();
              },
            });
          } else {
            that.$message({
              showClose: true,
              message: resp.data.msg,
              type: "error",
            });
          }
        });
    },
    resetForm() {
      this.$refs["form"].resetFields();
      $(".zb").animate(
        {
          top: 23,
          "font-size": 14,
        },
        300
      );
      $(".zb").css({
        background: "white",
        color: "rgb(133, 133, 133)",
      });
    },
  },
};
</script>

<style scoped>
li {
  list-style: none;
}
hr {
  line-height: 0;
  margin: 10px 0;
  padding: 0;
  border: none !important;
  border-bottom: 1px solid #eee !important;
  clear: both;
  background: 0 0;
}
.layui-bg-blue {
  background-color: #1e9fff !important;
  margin: 15px 0 15px 0 !important;
}
#showMessage,
.showcomments {
  background: white;
  padding: 20px;
  margin-bottom: 26px;
  box-shadow: rgb(112 112 112 / 18%) 0px 0px 20px 10px;
  border-radius: 15px;
}

.zb {
  position: absolute;
  left: 22px;
  top: 23px;
  color: rgb(186, 186, 186);
  line-height: initial;
}

#showMessage .layui-input {
  height: 50px;
  font-size: 18px;
  border-radius: 15px;
  width: 60%;
}

#showMessage input::placeholder {
  font-size: 15px;
}

#showMessage .layui-form-label {
  font-size: 18px;
  line-height: 30px;
}
</style>